import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  computed, inject,
  input, OnInit,
  output,
  signal,
  Signal
} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {RouterLink} from "@angular/router";
import {BreakpointObserver} from "@angular/cdk/layout";
import {NgIf} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    MatIcon,
    RouterLink,
    NgIf,
    MatTooltip
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);
  private cdk = inject(ChangeDetectorRef);

  isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');


  itemsAround : Signal<number> = signal(2);

  current = input.required<number>({ alias: 'current' });
  limit = input.required<number>({ alias: 'limit' });
  count = input.required<number>({ alias: 'count' });
  showFirst = computed(() => this.current() - 1 > 1);
  showPrevious = computed(() => this.current() > 1);
  showNext = computed(() => this.current() < this.pages());
  showLast = computed(() => this.current() + 1 < this.pages());

  pages = computed(() => Math.ceil(this.count() / this.limit() ));

  start = computed(() => {
    if ((this.current() - this.itemsAround()) > 1) {
      return this.current() - this.itemsAround();
    }

    return 1;
  });

  onPageChange = output<number>();

  end = computed(() => {
    if ((this.current() + this.itemsAround()) > this.pages()) {
      return this.pages();
    }

    return this.current() + this.itemsAround();
  });

  range = computed(() => {
    if (this.end() <= this.start()) {
      return [];
    }

    return Array.from(
      Array(this.end() - this.start() + 1).keys()
    ).map(x => x + this.start());
  });

  changePage(page: number) {
    this.onPageChange.emit(page);
  }

  ngOnInit() {
    this.breakpointObserver.observe('(max-width: 599px)').subscribe(result => {
      this.isSmallScreen = result.matches;
      this.cdk.markForCheck();
    })
  }
}
