import { Pipe, PipeTransform } from '@angular/core';
import {ContentInterface} from "../content.interface";

@Pipe({
  name: 'contentUrl',
  standalone: true
})
export class ContentUrlPipe implements PipeTransform {
  transform(value: ContentInterface): string {
    return '/utwory/czytaj/' + value.slug;
  }
}
