export enum ContentStatus {
  ACCEPTED
}

export interface ContentList {
  count: number;
  page: number;
  pages: number;
  data: ContentInterface[];
}

export enum ContentType {
  poem = 'POEM', article = 'ARTICLE', book = 'BOOK', feuilleton = 'FEUILLETON', story = 'STORY'
}



export interface ContentInterface {
  _id: string;
  status: ContentStatus;
  votes: number;
  content_type: ContentType;
  title: string;
  created_at: Date;
  published: boolean;
  updated_at?: Date;
  content: string;
  visitors: number;
  published_at: Date;
  comments_count: number;
  signature: string;
  votes_average: number;
  votes_sum: number;
  author_id: string;
  author: AuthorInterface|null;
  comments?: CommentInterface[];
  scores?: string[];
  deleted_at: Date;
  slug: string;
}

export interface AuthorInterface {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  avatar: string;
  about: string;
}

export interface CommentInterface {
  _id: string;
  created_at: Date|string;
  deleted_at?: Date;
  author: AuthorInterface|null;
  author_id?: string;
  content: string;
  publication_id: string;
  signature: string;
  status: number;
}

export interface ContentCommentData {
  content: string;
}
