import { Pipe, PipeTransform } from '@angular/core';
import {AuthorInterface, User} from "../user";
import {isPresent} from "../../core/util";
// import GetImageUrl from "../../ui-kit/util/img-proxy";

// const defaultPreset = 'avatar-small';
const defaultAvatarPath = 'default/users/avatars/avatar.png';

@Pipe({
  name: 'avatarPath',
  standalone: true
})
export class AvatarPathPipe implements PipeTransform {
  // transform(value: AuthorInterface|User|null, preset?: string): string {
  transform(value: AuthorInterface|User|null|undefined): string {

    let avatarPath = defaultAvatarPath;

    if (isPresent(value) && isPresent(value?.avatar)) {
      avatarPath = value!.avatar;
    }

    return avatarPath
  }

}
