<nav class="pagination">

  <ul class="pages">
    @if (showPrevious()) {
      @if (showFirst()) {
        <li>
          <a [routerLink]="[]" [queryParams]="{page: 1}"
             class="item"
             aria-label="Pierwsza strona"
             [matTooltip]="'Pierwsza strona'"
             (click)="changePage(1)"
          >
            <mat-icon [svgIcon]="'mat_outline:keyboard_double_arrow_left'"></mat-icon>
          </a>
        </li>
      }
      <li *ngIf="!isSmallScreen">
        <a [routerLink]="[]" [queryParams]="{page: current() - 1}"
           class="item"
           aria-label="Poprzednia strona"
           [matTooltip]="'Poprzednia strona'"
           (click)="changePage(current() - 1)"
        >
          <mat-icon [svgIcon]="'mat_outline:keyboard_arrow_left'"></mat-icon>
        </a>
      </li>
    }
    @for (page of range(); track page) {
      <li class="item">
        @if (page == current()) {
          <a [routerLink]="[]" class="item" [class.current]="page == current()" disabled>{{ page }}</a>
        } @else {
          <a
            [routerLink]="[]" [queryParams]="{page: page}"
            class="item"
            [attr.aria-label]="page"
            (click)="changePage(page)"
          >{{ page }}</a>
        }
      </li>
    }
    @if (showNext()) {
      <li *ngIf="!isSmallScreen">
        <a
          [routerLink]="[]" [queryParams]="{page: current() + 1}"
          class="item"
          aria-label="Następna strona"
          [matTooltip]="'Następna strona'"
          (click)="changePage(current() + 1)"
        >

          <mat-icon [svgIcon]="'mat_outline:keyboard_arrow_right'"></mat-icon>
        </a>
      </li>
      @if (showLast()) {
        <li>
          <a
            [routerLink]="[]" [queryParams]="{page: pages()}"
            class="item"
            aria-label="Ostatnia strona"
            [matTooltip]="'Ostatnia strona'"
            (click)="changePage(pages())"
          >

            <mat-icon [svgIcon]="'mat_outline:keyboard_double_arrow_right'"></mat-icon>
          </a>
        </li>
      }
    }
  </ul>
</nav>
