import {Pipe, PipeTransform} from '@angular/core';
import {ContentType} from "../content.interface";

export function ContentTypeToLabel(value: ContentType|null, plural: boolean) {
  switch (value) {
    case ContentType.article:
      return plural ? "Artykuły" : "Artykuł";
    case ContentType.book:
      return plural ? "Książki" : "Książka"
    case ContentType.poem:
      return plural ? "Wiersze" : "Wiersz";
    case ContentType.story:
      return plural ? "Opowiadania" : "Opowiadanie";
    case ContentType.feuilleton:
      return plural ? "Felietony" : "Felieton";
  }

  return '';
}

export function ContentTypeToRoute(value: ContentType, plural: boolean = true) {
  switch (value) {
    case ContentType.article:
      return plural ? "artykuly" : 'artykul';
    case ContentType.book:
      return plural ? "ksiazki": 'ksiazka';
    case ContentType.poem:
      return plural ? "wiersze" : 'wiersz';
    case ContentType.story:
      return plural ? "opowiadania": 'opowiadanie';
    case ContentType.feuilleton:
      return plural ? "felietony": 'felieton';
  }
}

export type RouteContentType = 'artykuly' | 'artykul' | 'ksiazki' | 'ksiazka' | 'wiersze' | 'wiersz' | 'opowiadania' | 'opowiadanie' | 'felietony' | 'felieton';

export function RouteToContentType(value: RouteContentType) {
  switch (value) {
    case "artykuly":
    case "artykul":
      return ContentType.article;
    case "ksiazki":
    case "ksiazka":
      return ContentType.book
    case "wiersze":
    case "wiersz":
      return ContentType.poem;
    case "opowiadania":
    case "opowiadanie":
      return ContentType.story;
    case "felietony":
    case "felieton":
      return ContentType.feuilleton;
  }
}

export function RouteToLabel(value: RouteContentType, plural: boolean) {
  return ContentTypeToLabel(RouteToContentType(value), plural);
}

@Pipe({
  name: 'contentType',
  standalone: true
})
export class ContentTypePipe implements PipeTransform {

  transform(value: ContentType|null, plural: boolean = false): string {
    return ContentTypeToLabel(value, plural);
  }
}
