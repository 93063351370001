import {patchState, signalStore, withComputed, withMethods, withState} from "@ngrx/signals";
import {computed, inject} from "@angular/core";
import { withCallState} from "../state/call-state.feature";
import {CommentInterface, ContentCommentData, ContentInterface, ContentType} from "./content.interface";
import {ContentService} from "./content.service";
import {catchError, firstValueFrom, forkJoin, tap} from "rxjs";
import {NotificationService} from "../core/notification/notification.service";
import {tapResponse} from "@ngrx/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";

export type SortField = 'published_at' | 'votes_average' | 'created_at';
export type SortDirection = 'asc' | 'desc';

export interface ContentStateInterface {
  entities: ContentInterface[],
  entity: ContentInterface | null,
  entityComments: CommentInterface[]
  entityScores: any[]
  query: Query,
}

export interface Query {
  contentType: ContentType | null
  sortField: SortField,
  sortOrder: SortDirection,
  author: string |undefined | null,
  page: number,
  limit: number,
  count: number
}

const defaultContentState = {
  query: {
    contentType: null,
    sortField: 'published_at',
    sortOrder: 'desc',
    author: null,
    page: 1,
    limit: 12,
    count: 12
  },
  entity: null,
  entityComments: [],
  entityScores: [],
  entities: []
} as ContentStateInterface

export const ContentStore = signalStore({providedIn: 'root', protectedState: false },
  withState<ContentStateInterface>(defaultContentState),
  withComputed(store => ({
    searchQuery: computed(() => {
      const query = store.query()

      return Object.fromEntries(Object.entries(query).filter(([_, v]) => v != null));
    })
  })),
  withCallState({collection: 'content'}),
  withMethods((store, router = inject(Router), notificationService = inject(NotificationService), contentService = inject(ContentService)) => ({
    withContentType(type: ContentType|null) {
      patchState(store, {query: {...store.query(), contentType: type, page: 1}});
    },
    async withPagination(page: number, limit: number|null = null) {
      let url = router.url.split('?')[0];
      let params = {};

      patchState(store, {query: {...store.query(), page: page, limit: limit || store.query().limit}});

      if (page && page > 1) {
        params = {page: page}
      }

      await router.navigate([decodeURIComponent(url)], {queryParams: params, queryParamsHandling: "merge"})
    },
    withAuthor(author: string|undefined|null) {
      patchState(store, {query: {...store.query(), author: author, page: 1}});
    },
    withSort(sortField: SortField, sortDirection: SortDirection) {
      patchState(store, {query: {...store.query(), sortField: sortField, sortOrder: sortDirection, page: 1}});
    },
    async bySlug(slug: string) {
      return firstValueFrom(contentService.getContentBySlug(slug).pipe(
        catchError((error) => {
          if (error.status === 404) {
            router.navigate(['/errors/404'])
          }

          throw error;
        }),
        tap((data) => {
          patchState(store, {entity: data});

          contentService.getContentComments(data._id).pipe(
            tap((comments) => {
              patchState(store, {entityComments: comments});
            })
          ).subscribe()

          contentService.getScores(data._id).pipe(
            tap((scores) => {
              patchState(store, {entityScores: scores});
            })
          ).subscribe()
        })
      ))
    },
    async fetchComments(id: string) {
      const data = await firstValueFrom(
        contentService.getContentComments(id)
      );
      patchState(store, {entityComments: data});
    },
    async fetchScores(id: string) {
      const data = await firstValueFrom(
        contentService.getScores(id)
      );
      patchState(store, {entityScores: data});
    },
    async submitComment(content: ContentInterface, comment: ContentCommentData) {
      await firstValueFrom(
        contentService.postComment(content._id, comment)
      ).then(() => {
        firstValueFrom(
          forkJoin([
            contentService.getContentComments(content._id),
            contentService.getContentBySlug(content.slug)
          ])
        ).then(data => {
          patchState(store, {entityComments: data[0]});
          patchState(store, {entity: data[1]});
        })
      });
    },
    async submitScore(content: ContentInterface, score: number) {
      return await firstValueFrom(
        contentService.submitScore(content._id, score).pipe(
          tapResponse(({
            next: async () => {
              notificationService.success('Ocena została zapisana')
              await this.fetchScores(content._id)
              await this.bySlug(content.slug)
            },
            error: (error: HttpErrorResponse) => {
              if (error.status === 403) {
                notificationService.error(error.error.error_description)
              } else {
                notificationService.error('Wystąpił błąd podczas zapisywania oceny')
              }
            }
          }))
        )
      )
        // .then(() => {
        // firstValueFrom(
        //   forkJoin([
        //     contentService.getScores(content._id),
        //     contentService.getContentBySlug(content.slug)
        //   ])
        // ).then(data => {
        //   patchState(store, {entityScores: data[0]});
        //   patchState(store, {entity: data[1]});
        // })
      // });
    },
    async load() {
      const query = store.searchQuery()
      return firstValueFrom(contentService.getContent(query)).then(data => {
        patchState(store, {entities: data.data})
        patchState(store, {query: {...store.query(), count: data.count}})
      })
    },
    loadMyContent() {
      const query = store.searchQuery()

      firstValueFrom(contentService.getMyContent(query)).then(data => {
        patchState(store, {entities: data.data})
        patchState(store, {query: {...store.query(), count: data.count}})
      })

    }
  })),
  // withHooks((store, router = inject(ActivatedRoute)) => ({
  //   onInit() {
  //     // router.queryParams.subscribe(params => {
  //     //   store.withPagination(Number(params['page']) || 1)
  //     // })
  //     const page = router.snapshot.queryParamMap.get('page')
  //     patchState(store, {query: {...store.query(), page: parseInt(page || '1')}})
  //
  //     router.queryParamMap.subscribe((params) => {
  //       const page = params.get('page');
  //
  //       console.log('page: ' + page)
  //       if (page) {
  //         patchState(store, {query: {...store.query(), page: parseInt(page)}})
  //       }
  //
  //     })
  //   }
  // }))
)


