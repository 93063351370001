<div class="hero relative">
  <div class="image">
    <div class="bg"></div>

    <img
      [fill]="true"
      [priority]="true"
      [ngSrc]="'default/core/hero-image.jpg'"
      title="hero" alt="">
  </div>
  <div class="slogan">
    <div>
      <h1 class="md:!leading-[110%]">
        Poetica.pl
      </h1>
      <p>Aby być sobą, aby tworzyć</p>
    </div>
  </div>
</div>
