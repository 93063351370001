import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ContentCommentData, ContentInterface, ContentList} from './content.interface';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  _httpClient = inject(HttpClient)
  public submitScore(id: string, value: number): Observable<any> {
    return this._httpClient.post(`/api/v1/content/${id}/scores`, { score: value })
  }

  public score(id: string, value: number): Observable<any> {
    return this._httpClient.post<any>(`/api/v1/content/${id}/scores`, { score: value });
  }

  public read(id: string): Observable<any> {
    return this._httpClient.put(`/api/v1/content/${id}/read`, {});
  }

  public getScores(id: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`/api/v1/content/${id}/scores`);
  }

  public getContentComments(id: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`/api/v1/content/${id}/comments`);
  }

  public getMyContent(query: any): Observable<ContentList> {
    return this._httpClient.get<ContentList>('/api/v1/content/my-content', { params: new HttpParams({fromObject: query}) });
  }

  public getContent(query: any): Observable<ContentList> {
    return this._httpClient.get<ContentList>('/api/v1/content', { params: new HttpParams({fromObject: query}) });
  }

  public deleteContent(content: ContentInterface): Observable<any> {
    return this._httpClient.delete<ContentList>(`/api/v1/content/${content._id}`);
  }

  public getContentById(id: string): Observable<ContentInterface> {
    return this._httpClient.get<ContentInterface>(`/api/v1/content/${id}`);
  }

  public publish(content: { _id: string }): Observable<any> {
    return this._httpClient.put<ContentList>(`/api/v1/content/${content._id}/publish`, null);
  }

  public unpublish(content: { _id: string }): Observable<any> {
    return this._httpClient.put<ContentList>(`/api/v1/content/${content._id}/unpublish`, null);
  }

  public getContentBySlug(slug: string): Observable<ContentInterface> {
    return this._httpClient.get<ContentInterface>(`/api/v1/content/bySlug/${slug}`);
  }

  public getLatestContent(): Observable<ContentList> {
    return this.getContent({
      sortField: 'published_at',
      sortOrder: 'desc'
    });
  }

  public getBestContent() {
    return this.getContent({
      sortField: 'votes_average',
      sortOrder: 'desc'
    });
  }

  public getBestContentByAuthor(authorId: any) {
    return this.getContent({
      sortField: 'votes_average',
      sortOrder: 'desc',
      author_id: authorId
    });
  }

  public getLatestContentByAuthor(authorId: any) {
    return this.getContent({
      sortField: 'published_at',
      sortOrder: 'desc',
      author_id: authorId
    });
  }

  postComment(id: string, model: ContentCommentData) {
    return this._httpClient.post<any>(`/api/v1/content/${id}/comments`, model);
  }

  deleteComment(id: string, commentId: string) {
    return this._httpClient.delete(`/api/v1/content/${id}/comments/${commentId}`);
  }

  create(model: any) : Observable<any> {
    return this._httpClient.post<any>(`/api/v1/content`, model)
  }

  update(id: string, model: any) : Observable<any> {
    return this._httpClient.put<any>(`/api/v1/content/${id}`, model)
  }
}
