import { Component } from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {DEFAULT_PAGE_METADATA} from "../../../content/seo.service";

@Component({
  selector: 'app-homepage-hero',
  standalone: true,
  host: {ngSkipHydration: 'true'},
  imports: [NgIf, NgOptimizedImage],
  templateUrl: './homepage-hero.component.html',
  styleUrl: './homepage-hero.component.scss'
})
export class HomepageHeroComponent {
  public imageTitle = DEFAULT_PAGE_METADATA.title
}
