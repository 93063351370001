import { Pipe, PipeTransform } from '@angular/core';
import {ContentType} from "../content.interface";

@Pipe({
  name: 'listUrl',
  standalone: true
})
export class ListUrlPipe implements PipeTransform {

  transform(value: ContentType|null): string {
    switch (value) {
      case ContentType.article:
        return "/utwory/artykuly";
      case ContentType.book:
        return "/utwory/ksiazki";
      case ContentType.poem:
        return "/utwory/wiersze";
      case ContentType.story:
        return "/utwory/opowiadania";
      case ContentType.feuilleton:
        return "/utwory/felietony";
    }

    return '/utwory';
  }

}
